import React, { FunctionComponent } from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { JobPostList } from '../components/job-post-list'
import styled from 'styled-components'

const Title = styled.h1`
  margin: 0 !important;
`

const Subtitle = styled.h2`
  color: rgba(0, 0, 0, .54) !important;
  margin-top: 5px !important;
`

const Content = styled.div`
  margin-top: 50px;
`

const RelatedPostsContainer = styled.div`
`

interface Service {
  slug: string
  title: string
}

interface JobPostTemplateProps {
  content: any
  description: string
  tags: any[]
  title: string
  helmet: any
  service: Service | null
  relatedPosts?: any[]
}

export const JobPostTemplate: FunctionComponent<JobPostTemplateProps> = ({
  content,
  description,
  tags,
  title,
  helmet,
  relatedPosts = [],
}) => {
  return (
    <>
      {helmet || ''}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div style={{ marginBottom: 20 }}>
                <Link to="/careers#jobPosts">채용 공고 목록으로 돌아가기</Link>
              </div>
              <div className="content">
                <Title className="title is-size-2 has-text-weight-normal is-bold-light">
                  {title}
                </Title>
                <Subtitle className="has-text-weight-normal">
                  {description}
                </Subtitle>
                <div className="tags">
                  {tags.map(tag => (
                    <span key={tag} className="tag">{tag}</span>
                  ))}
                </div>
                <Content>
                  {content}
                </Content>
              </div>
              {false && tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>태그</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ backgroundColor: '#fafafa' }}>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <RelatedPostsContainer>
                <h3 className="title is-3">관련 있는 채용 공고</h3>
                <JobPostList posts={relatedPosts} />
              </RelatedPostsContainer>
              <div style={{ marginTop: 40 }}>
                <Link to="/careers#jobPosts">채용 공고 목록으로 돌아가기</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

interface JobPostProps {
  data: any
}

const MAX_RELATED_POST_COUNT = 3

function getRelatedPosts(tags, allPosts) {
  const posts = allPosts.slice()
  const result = []

  for (const tag of tags) {
    for (let i = posts.length - 1; i >= 0; --i) {
      const post = posts[i]
      if (post.frontmatter.tags.indexOf(tag) >= 0) {
        posts.splice(i, 1)
        result.push(post)
        if (result.length >= MAX_RELATED_POST_COUNT) {
          return result
        }
      }
    }
  }

  return result
}

const JobPost: FunctionComponent<JobPostProps> = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark } = data
  const { service } = post.frontmatter

  const posts = allMarkdownRemark.edges
    .map(({ node }) => node)
    .filter(node => node.id !== post.id)

  const relatedPosts = getRelatedPosts(post.frontmatter.tags, posts)

  return (
    <Layout>
      <JobPostTemplate
        content={
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        }
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | 채용 공고">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        service={service && {
          slug: service.fields.slug,
          title: service.frontmatter.title,
        }}
        relatedPosts={relatedPosts}
      />
    </Layout>
  )
}

export default JobPost

export const pageQuery = graphql`
  query JobPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        service {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "job-post" }, closed: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date(formatString: "MMMM DD, YYYY")
            service {
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            tags
          }
        }
      }
    }
  }
`
