import React, { forwardRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Image from './PreviewCompatibleImage'
import defaultLogoURL from '../img/favicon.png'

const MediaContent = styled.div.attrs({ className: 'media-content' })`
  overflow-x: unset !important;
`

interface JobPostListProps {
  id?: string
  posts: any[]
}

export const JobPostList = forwardRef<HTMLDivElement, JobPostListProps>(({ id, posts }, ref) => {
  return (
    <div ref={ref}>
      {posts.map(post => {
        const service = post.frontmatter.service
        const serviceImage = service ? service.frontmatter.image : defaultLogoURL

        return (
          <article className="media" key={post.id}>
            <figure className="media-left">
              <div className="image is-64x64">
                <Image imageInfo={{ image: serviceImage }} />
              </div>
            </figure>
            <MediaContent>
              <div className="content">
                <p>
                  <Link to={post.fields.slug}>
                    <strong>{post.frontmatter.title}</strong>
                  </Link>
                  <br />
                  {post.frontmatter.description}
                </p>
              </div>
              <div className="tags">
                {post.frontmatter.tags.map(tag => (
                  <span key={tag} className="tag">{tag}</span>
                ))}
              </div>
            </MediaContent>
          </article>
        )
      })}
    </div>
  )
})
